import React from 'react'
import './Footer.css'
import { animateScroll as scroll} from 'react-scroll' 
import { FaLinkedin, FaTwitter, FaReddit, FaInstagram } from 'react-icons/fa'
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, FooterImg, LegalDiv } from './FooterElements'

const Footer = ({ img, alt, privacy, terms }) => {
	const toggleHome = () => {
		scroll.scrollToTop();
	}

	return (
		<FooterContainer>
			<FooterWrap>
				{/*
				<FooterLinksContainer>
					<FooterLinksWrapper>
						<FooterLinkItems>
							<FooterLinkTitle>About Us</FooterLinkTitle>
							<FooterLink to='/signin'>Technology</FooterLink>
							<FooterLink to='/signin'>Research and Development</FooterLink>
							<FooterLink to='/signin'>Careers</FooterLink>
							<FooterLink to='/signin'>Team</FooterLink>
							<FooterLink to='/signin'>Terms of Service</FooterLink>
						</FooterLinkItems>
					</FooterLinksWrapper>
				</FooterLinksContainer>
				*/}
				<SocialMedia>
					<SocialMediaWrap>
						<SocialLogo to='/' onClick={toggleHome}><FooterImg src={img} alt={alt}/></SocialLogo>
						<LegalDiv>
							<a class="privacy" href={privacy} target="_blank" rel="noreferrer">Privacy Policy</a>
							<p class="linkSeparator"> | </p>
							<a class="terms" href={terms} target="_blank" rel="noreferrer">Terms of Use</a>
						</LegalDiv>
						<WebsiteRights>© {new Date().getFullYear()} Attochron, LLC</WebsiteRights>
						<SocialIcons>
							<SocialIconLink href="//www.linkedin.com/company/attochron-llc/" target="_blank" aria-label="Linkedin">
								<FaLinkedin />
							</SocialIconLink>
							<SocialIconLink href="//twitter.com/attochron1" target="_blank" aria-label="Twitter">
								<FaTwitter />
							</SocialIconLink>
							<SocialIconLink href="//www.reddit.com/user/Attochron/" target="_blank" aria-label="Reddit">
								<FaReddit />
							</SocialIconLink>
							<SocialIconLink href="//www.instagram.com/attochronllc/" target="_blank" aria-label="Instagram">
								<FaInstagram />
							</SocialIconLink>
						</SocialIcons>
					</SocialMediaWrap>
				</SocialMedia>
			</FooterWrap>
		</FooterContainer>
	)
}

export default Footer