export const aboutObjOne = {
	id: "about",
	aboutTitle: "About Us",
	aboutTitle2: "About OWC",

	heading: 'Attochron is Developing the Next Generation of "Light Waves" for Optical Wireless Communications',
	description: 'Inspired by lightning research, Attochron invented a new optical wireless core technology of using *ultrashort pulse lasers (USPL)* as the signal carrier instead of industry standard continuous wave lasers (CWL). Attochron-spec USPLs are solving some of the longest standing challenges for sending laser signals through the air.',
	description5: 'The most common challenge to this wireless *propagation* of lasers is known as scintillation, for which Attochron-spec USPLs have effectively solved. The Attochron solution also doubles link budget at the detector with *impulsive coding* while presenting the opportunity to scale data rates in excess of terabits per second (Tbps) with its extremely broad optical spectrum (equal to 3500 GHz).',
	link: "https://patents.google.com/?assignee=attochron&oq=attochron",

	img: require('../../images/Tom_with_Ptype.JPG'),
	alt: "ABOUT US",

	heading2: 'Optical Wireless Communications (OWC) & Solving the Digital Divide',
	description2: 'Broadband demand has been increasing exponentially globally since the launch of the Internet. Yet billions of people are still without reliable Internet regardless of their country. The tools bringing broadband to the people include fiber optics (light waves inside glass fiber) and radio waves.', 
	description3: 'Amazingly, most Internet access points (enterprises, cell sites, multi-dwelling units, like apartment buildings) are less than a mile from the main fiber optic supply lines... but the cost to extend fiber optics to those millions of Internet access points over this "last-mile" is cost prohibitive for any government or private company to bear.',
	description4: 'So why not bring light waves through the air to complement these solutions and solve this "last-mile" problem? This concept (light waves through the air) is known as Optical Wireless Communications (OWC) and has been attempted before. But the ever changing atmosphere has challenged technologists from delivering a reliable light wave signal... until now.',
	img2: require('../../images/phone.png'),
	alt2: 'ABOUT US'
}