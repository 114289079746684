import React from 'react'
import './Dev.css'
import { DevContainer, DevTitle, DevWrapper, DevRow, Column1, 
	TextWrapper, Heading, Heading2, Description, Column2, ImgWrap, DataLink, BREAK, Link } from './ResearchElements'


const ResearchSection = ({ id, devTitle, heading, heading2, description, description2, description3, description4, description5, description6, description7, description8,
	link, link2, link3, link4, img, img2, img3, img4, img5, img6, alt, alt2, alt3, alt4, alt5, alt6 }) => {
	return (
		<>
			<DevContainer id={id}>
				<DevTitle>{devTitle}</DevTitle>
				<DevWrapper>
					<DevRow>
						<Column1>
							<TextWrapper>
								<Heading>{heading}</Heading>
								<Description>{description}</Description>
								<img class="devImg4" src={img4} alt={alt4}/>
								<Heading2>Attochron Incoherent Summation Advantage&trade; (ISA&trade;)</Heading2>
								<Description>Key to Attochron's proprietary system is the very short coherence length of the signals. Lasting approximately 10^-14/seconds, these pulses provide a number of advantages including extreme spectral bandwidth (~3500 GHz), high peak pulse power (kW+), and extremely precise timing (femtosecond jitter).</Description>
								<Description>When the Attochron-spec USPL is propagated through atmosphere, the very short coherence length of each pulse prevents any beamlets of each pulse from interfering with each other. Attochron's Incoherent Summation Advantage&trade; (ISA&trade;) provides a near-elimination of one of the largest challenges to optical wireless communications: coherent noise brought on by scintillation.</Description>
								<Description>{description3}</Description>
								<Description>{description6}</Description>
								<Description>{description8}</Description>
								<Description>This speckle creates noise causing BER to suffer due to the coherent interference. This phenomena also makes it difficult to focus the received light on a small detector or fiber entrance when working with fast data rates and sensitive detectors. (<Link class="devDescLink" to='news' smooth={true} duration={700} spy={true} exact='true' offset={-80}>see News & Demos</Link>)</Description>
								<Description>By contrast, the Attochron-spec USPL with ISA&trade;, on the left in the colored GIF, does not experience this coherent interference. Any shifting of the USPL pulses by the atmosphere only occurs outside the pulse coherence length (see propagation model graphic). USPL pulses simply add their power together without the possibility of the electric fields in each pulse interfering with each other and causing noise. This video shows the superior beam quality of the USPL and ISA&trade;.</Description>
								<Description>{description5}</Description>
								{/* <DataLink2 to='news' smooth={true} duration={700} spy={true} exact='true' offset={-80}>View Our Demos</DataLink2> */}
								<BREAK></BREAK>
								<DataLink href={link2} target="_blank">View Our Data</DataLink>
								<BREAK></BREAK>
								<DataLink href={link} target="_blank">Attochron White Paper</DataLink>
								<BREAK></BREAK>
								<DataLink href={link3} target="_blank">Attochron Patents</DataLink>
								<BREAK></BREAK>
								<DataLink href={link4} target="_blank">ALTIS-7 Datasheet</DataLink>
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<img class="devImg2" src={img2} alt={alt2}/>
								<img class="devImg" src={img} alt={alt}/>
								<img class="devImg" src={img6} alt={alt6}/>
								<img class="devImg" src={img5} alt={alt5}/>
								<img class="devImg3" src={img3} alt={alt3}/>
								{/* <img class="devImg" src={img2} alt={alt}/> */}
							</ImgWrap>
						</Column2>
					</DevRow>
				</DevWrapper>
			</DevContainer>
		</>
	)
}

export default ResearchSection