export const teamObjOne = {
	teamTitle: "Our Team",

	link1: "https://www.linkedin.com/in/tomchaffee/",
	img1: require('../../images/tomChaffee.png'),
	alt1: require('../../images/blankPerson.png'),
	name1: "Tom Chaffee",
	description1: "Founder, CEO & Managing Member",

	link2: "https://www.linkedin.com/in/jamesmichaelolson/",
	img2: require('../../images/jimOlson.png'),
	alt2: require('../../images/blankPerson.png'),
	name2: "Jim Olson",
	description2: "Sr. VP Sales and Marketing",

	link3: "https://www.linkedin.com/in/brian-gregory-454501177/",
	img3: require('../../images/brianGregory.png'),
	alt3: require('../../images/blankPerson.png'),
	name3: "Brian Gregory",
	description3: "Lead Electrical & Software Engineer",

	link4: "https://www.linkedin.com/in/taz-colangelo-5a0275140/",
	img4: require('../../images/tazColangelo.png'),
	alt4: require('../../images/blankPerson.png'),
	name4: "Taz Colangelo",
	description4: "Lead Optics & Photonics Engineer",

	link5: "https://www.linkedin.com/in/carter-winfield-4279b91a3/",
	img5: require('../../images/carterWinfield.jpg'),
	alt5: require('../../images/blankPerson.png'),
	name5: "Carter Winfield",
	description5: "Software & Computer Engineer",

	link6: "https://www.linkedin.com/in/sean-carrington-46603b207/",
	img6: require('../../images/seanCarrington.jpg'),
	alt6: require('../../images/blankPerson.png'),
	name6: "Sean Carrington",
	description6: "Director of Facilities & Logistics",

	link7: "https://www.linkedin.com/in/perry-siehien-06844585/",
	img7: require('../../images/perrySiehien.png'),
	alt7: require('../../images/blankPerson.png'),
	name7: "Perry Siehien",
	description7: "Data Analyst & Software Engineer",

	link8: "https://www.linkedin.com/in/garrett-olson-510633161/",
	img8: require('../../images/garrettOlson.png'),
	alt8: require('../../images/blankPerson.png'),
	name8: "Garrett Olson",
	description8: "Network Engineer",


	consultantTitle: "Our Consultants",

	link9: "https://www.linkedin.com/in/wayne-knox-1505812/",
	img9: require('../../images/wayneKnox.png'),
	alt9: require('../../images/blankPerson.png'),
	name9: "Dr. Wayne Knox",
	description9: "Optical Physics and Telecommunications Engineering",

	link10: "https://www.linkedin.com/in/david-robie-phd-10448b1/",
	img10: require('../../images/daveRobie.jpg'),
	alt10: require('../../images/blankPerson.png'),
	name10: "Dr. Dave Robie",
	description10: "Project Manager",

	link11: "https://www.linkedin.com/in/aaron-freeman-62b7a8b/",
	img11: require('../../images/aaronFreeman.png'),
	alt11: require('../../images/blankPerson.png'),
	name11: "Dr. Aaron Freeman",
	description11: "Director of Precision Pointing Controls at General Atomics",

	link12: "https://www.linkedin.com/in/patrick-caputo-b7796749/",
	img12: require('../../images/patrickCaputo.png'),
	alt12: require('../../images/blankPerson.png'),
	name12: "Patrick Caputo",
	description12: "Electrical and Photonics Engineer",

	link13: "https://www.linkedin.com/in/brad-niems-a441674/",
	img13: require('../../images/blankPerson.png'),
	alt13: require('../../images/blankPerson.png'),
	name13: "Brad Niems",
	description13: "Director of Business Development at Amphenol Broadband Solutions",

	link14: "https://eclipseoptics.com/",
	img14: require('../../images/eclipse.png'),
	alt14: require('../../images/blankPerson.png'),
	name14: "Eclipse Optics",
	description14: "Optical Design and Sensing",


	investorTitle: "Lead Investor",

	link15: "https://www.tcr-ny.com/",
	img15: require('../../images/tcrLogo.png'),
	alt15: require('../../images/blankPerson.png'),
	name15: "Three Cities Research, Inc.",
	description15: "Private Equity Firm",


	councilTitle: "Legal Counsel",

	link16: "https://www.linkedin.com/in/eric-d-blatt/",
	img16: require('../../images/ericBlatt.png'),
	alt16: require('../../images/blankPerson.png'),
	name16: "Eric Blatt, Scale LLP",
	description16: "Technologist and IP Attorney",

	link30: "https://www.linkedin.com/in/alexander-slafkosky/",
	img30: require('../../images/alexS.png'),
	alt30: require('../../images/blankPerson.png'),
	name30: "Alex Slafkosky, Scale LLP",
	description30: "Technologist and IP Attorney",


	advisoryTitle: "Advisory Board",

	link17: "https://www.linkedin.com/in/rob-fleishauer-6754639/",
	img17: require('../../images/robF.png'),
	alt17: require('../../images/blankPerson.png'),
	name17: "Dr. Rob Fleishauer",
	description17: "Atmospheric Scientist",

	link18: "https://www.linkedin.com/in/john-j-murray-iii-66b7843/",
	img18: require('../../images/johnMurray.png'),
	alt18: require('../../images/blankPerson.png'),
	name18: "John Murray",
	description18: "Marketing, sales, content development, training, corporate cirriculums, CRM development",

	link19: "https://www.linkedin.com/in/xlightsystems/",
	img19: require('../../images/tomF.png'),
	alt19: require('../../images/blankPerson.png'),
	name19: "Thomas Farrell",
	description19: "Digital signal processing systems, cameras, laser beam control and free space lasercom systems",

	link20: "https://www.linkedin.com/in/tom-barnett-3ba63411/",
	img20: require('../../images/tomBarnett.png'),
	alt20: require('../../images/blankPerson.png'),
	name20: "Tom Barnett",
	description20: "Director of Strategic Innovation at Lumen Technologies",

	link21: "https://www.linkedin.com/in/janis-a-valdmanis-91ba9a7",
	img21: require('../../images/janisV.png'),
	alt21: require('../../images/blankPerson.png'),
	name21: "Dr. Janis Valdmanis",
	description21: "General Manager at Thorlabs-UFO",


	partnerTitle: "Our Partners",

	link22: "https://boldergroup.com/",
	img22: require('../../images/bolder_groupT.png'),
	alt22: require('../../images/blankPerson.png'),

	link23: "https://www.thorlabs.com/",
	img23: require('../../images/thorlabsT.png'),
	alt23: require('../../images/blankPerson.png'),

	link24: "https://www.otthydromet.com/en/",
	img24: require('../../images/ottT.png'),
	alt24: require('../../images/blankPerson.png'),

	link25: "https://www.lumen.com/en-us/home.html ",
	img25: require('../../images/lumenT.png'),
	alt25: require('../../images/blankPerson.png'),

	link26: "https://nuphoton.com/ ",
	img26: require('../../images/nuphoton.png'),
	alt26: require('../../images/blankPerson.png'),

	link27: "https://www.dropletmeasurement.com/about/",
	img27: require('../../images/dropletT.png'),
	alt27: require('../../images/blankPerson.png'),

	link28: "https://www.keysight.com ",
	img28: require('../../images/keysightT.png'),
	alt28: require('../../images/blankPerson.png'),

	link29: "https://www.viavisolutions.com/en-us ",
	img29: require('../../images/viaviT.png'),
	alt29: require('../../images/blankPerson.png'),
	
}