import styled from 'styled-components'

export const TeamContainer = styled.div`
	color: #fff;
	// background: #414C54;
	background: rgba(64,75,83, 1);
	height: auto;
	padding-top: 50px;
	padding-bottom: 50px;

	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
`

export const TeamTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 200px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 140px;
	}
`

export const TeamWrapper = styled.div`
	display: grid;
	z-index: 1;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	// padding: 0 24px;
	justify-content: center;
	align-items: center;
`

export const TeamRow = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 3fr);
	align-self: center;
	justify-self: center;
	// background-color: #f00;
	margin-bottom: 40px;
	grid-template-areas: 'col1 col2 col3';

	@media screen and (max-width: 768px) {

	}
`

export const Column1 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col1;
	width: 100%;
	max-width: 300px;

	@media screen and (max-width: 768px) {
		max-width: 100%;
	}

	@media screen and (max-width: 375px) {
		padding: 0;
		padding-left: 5px;
	}

`

export const Column2 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
	width: 100%;
	max-width: 300px;

	@media screen and (max-width: 768px) {
		max-width: 100%;
	}

	@media screen and (max-width: 375px) {
		padding: 0;
	}
`

export const Column3 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col3;
	width: 100%;
	max-width: 300px;

	@media screen and (max-width: 768px) {
		max-width: 100%;
	}

	@media screen and (max-width: 375px) {
		padding: 0;
		padding-right: 5px;
	}
`

export const TeamName = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	padding-top: 10px;

	@media screen and (max-width: 768px) {
		font-size: 16px;
	}

	@media screen and (max-width: 375px) {
		font-size: 12px;
	}
`

export const Description = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 5px;

	@media screen and (max-width: 768px) {
		font-size: 14px;
	}

	@media screen and (max-width: 375px) {
		font-size: 12px;
	}
`

export const TeamRow2 = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 2fr);
	align-self: center;
	justify-self: center;
	// background-color: #f00;
	margin-bottom: 40px;
	grid-template-areas: 'col1 col2';

	@media screen and (max-width: 768px) {
		
	}
`

export const TeamRow3 = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-self: center;
	justify-self: center;
	// background-color: #f00;
	margin-bottom: 100px;
	grid-template-areas: 'col1';

	@media screen and (max-width: 768px) {
		
	}
`

export const AdvisoryTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 290px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 230px;
	}
`

export const ConsultantTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 290px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 230px;
	}
`

export const CouncilTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 290px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 230px;
	}
`

export const InvestorTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 290px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-top: 30px;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 230px;
	}
`

export const PartnerTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 290px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-top: 30px;
	margin-bottom: 80px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 230px;
	}
`