import styled from 'styled-components'

export const ContactContainer = styled.div`
	color: #fff;
	// background: #414C54;
	background: #010606;
	height: auto;
	padding-top: 50px;
	padding-bottom: 50px;

	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
`

export const ContactWrapper = styled.div`
	display: grid;
	z-index: 1;
	height: auto;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	// padding: 0 24px;
	justify-content: center;
`

export const ContactHeading = styled.h1`
	display: flex;
	z-index: 1;
	max-width: 220px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 160px;
	}
`

export const ContactForm = styled.div`
	// display: block;
	// z-index: 1;
	height: auto;
	width: 100%;
	max-width: 1100px;
	transform: translate(-50%, 0%);
	position: relative;
	right: 50%;
	border-radius: 10px;
	left: 50%;
	// padding: 0 24px;
	justify-content: center;
	align-items: center;
`

export const FormInput = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;
	max-width: 1100px;
`

export const FormTextarea = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;
	max-width: 1100px;
`

export const ButtonDiv = styled.div`
	display: flex;	
	align-items: center;
	justify-content: center;
`

// export const Button = styled.a`
// 	border-radius: 50px;
// 	background: ${({primary}) => (primary ? '#5DA2F2' : '#010606')};
// 	white-space: nowrap;
// 	padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
// 	color: ${({dark}) => (dark ? '#010606' : '#fff')};
// 	font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
// 	outline: none;
// 	border: none;
// 	cursor: pointer;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	transition: all 0.2s ease-in-out;
// 	width: 200px;
// 	max-width: 1100px;

// 	&:hover {
// 		transition: all 0.2s ease-in-out;
// 		background: ${({primary}) => (primary ? '#fff' : '#5DA2F2')};
// 	}
// `