import React from 'react'
import { FaLinkedin, FaTwitter, FaReddit, FaInstagram } from 'react-icons/fa'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarLinks, SocialIcons, SocialIconLink } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
	return (
		<SidebarContainer isOpen={isOpen} onClick={toggle}>
			<Icon onClick={toggle}>
				<CloseIcon/>
			</Icon>
			<SidebarWrapper>
				<SidebarMenu>
					<SidebarLink to="about" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>About</SidebarLink>
					{/* <SidebarLink to="technology" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>Technologies</SidebarLink> */}
					<SidebarLink to="dev" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>Research & Development</SidebarLink>
					<SidebarLink to="team" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>Team & Partners</SidebarLink>
					<SidebarLink to="news" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>News & Demos</SidebarLink>
					<SidebarLinks to="jobs" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>Jobs</SidebarLinks>
					<SidebarLink to="contact" onClick={toggle} smooth={true} duration={700} spy={true} exact='true' offset={-80}>Contact Us</SidebarLink>
					<SocialIcons>
						<SocialIconLink href="//www.linkedin.com/company/attochron-llc/" target="_blank" aria-label="Linkedin">
							<FaLinkedin />
						</SocialIconLink>
						<SocialIconLink href="//twitter.com/attochron1" target="_blank" aria-label="Twitter">
							<FaTwitter />
						</SocialIconLink>
						<SocialIconLink href="//www.reddit.com/user/Attochron/" target="_blank" aria-label="Reddit">
							<FaReddit />
						</SocialIconLink>
						<SocialIconLink href="//www.instagram.com/attochronllc/" target="_blank" aria-label="Instagram">
							<FaInstagram />
						</SocialIconLink>
					</SocialIcons>
				</SidebarMenu>
			</SidebarWrapper>
		</SidebarContainer>
	)
}

export default Sidebar