import React from 'react'
import './team.css'
import { TeamContainer, TeamTitle, TeamName, TeamWrapper, TeamRow, 
	Column1, Description, Column2, Column3, TeamRow2, AdvisoryTitle, ConsultantTitle,
	CouncilTitle, TeamRow3, InvestorTitle, PartnerTitle } from './TeamElements'

const TeamSection = ({ teamTitle, advisoryTitle, consultantTitle, councilTitle, investorTitle, partnerTitle,
	link1, img1, alt1, name1, description1,
	link2, img2, alt2, name2, description2,
	link3, img3, alt3, name3, description3,
	link4, img4, alt4, name4, description4,
	link5, img5, alt5, name5, description5,
	link6, img6, alt6, name6, description6,
	link7, img7, alt7, name7, description7,
	link8, img8, alt8, name8, description8,
	link9, img9, alt9, name9, description9,
	link10, img10, alt10, name10, description10,
	link11, img11, alt11, name11, description11,
	link12, img12, alt12, name12, description12,
	link13, img13, alt13, name13, description13,
	link14, img14, alt14, name14, description14,
	link15, img15, alt15, name15, description15,
	link16, img16, alt16, name16, description16,
	link17, img17, alt17, name17, description17,
	link18, img18, alt18, name18, description18,
	link19, img19, alt19, name19, description19,
	link20, img20, alt20, name20, description20,
	link21, img21, alt21, name21, description21,
	link22, img22, alt22, name22, description22,
	link23, img23, alt23, name23, description23,
	link24, img24, alt24, name24, description24,
	link25, img25, alt25, name25, description25,
	link26, img26, alt26, name26, description26,
	link27, img27, alt27, name27, description27,
	link28, img28, alt28, name28, description28,
	link29, img29, alt29, name29, description29,
	link30, img30, alt30, name30, description30 }) => {
	return (
		<>
			<TeamContainer id="team">
				<TeamTitle>{teamTitle}</TeamTitle>
				<TeamWrapper>
					<TeamRow2>
						<Column1>
							<a href={link1} target="_blank" rel="noreferrer"><img class="teamImg" src={img1} alt={alt1} /></a>
							<TeamName>{name1}</TeamName>
							<Description>{description1}</Description>
						</Column1>
						<Column2>
							<a href={link2} target="_blank" rel="noreferrer"><img class="teamImg" src={img2} alt={alt2} /></a>
							<TeamName>{name2}</TeamName>
							<Description>{description2}</Description>
						</Column2>
					</TeamRow2>
					<TeamRow>
						<Column1>
							<a href={link3} target="_blank" rel="noreferrer"><img class="teamImg" src={img3} alt={alt3} /></a>
							<TeamName>{name3}</TeamName>
							<Description>{description3}</Description>
						</Column1>
						<Column2>
							<a href={link4} target="_blank" rel="noreferrer"><img class="teamImg" src={img4} alt={alt4} /></a>
							<TeamName>{name4}</TeamName>
							<Description>{description4}</Description>
						</Column2>
						<Column3>
							<a href={link5} target="_blank" rel="noreferrer"><img class="teamImg" src={img5} alt={alt5} /></a>
							<TeamName>{name5}</TeamName>
							<Description>{description5}</Description>
						</Column3>
					</TeamRow>
					<TeamRow>
						<Column1>
							<a href={link6} target="_blank" rel="noreferrer"><img class="teamImg" src={img6} alt={alt6} /></a>
							<TeamName>{name6}</TeamName>
							<Description>{description6}</Description>
						</Column1>
						<Column2>
							<a href={link7} target="_blank" rel="noreferrer"><img class="teamImg" src={img7} alt={alt7} /></a>
							<TeamName>{name7}</TeamName>
							<Description>{description7}</Description>
						</Column2>
						<Column3>
							<a href={link8} target="_blank" rel="noreferrer"><img class="teamImg" src={img8} alt={alt8} /></a>
							<TeamName>{name8}</TeamName>
							<Description>{description8}</Description>
						</Column3>
					</TeamRow>
				</TeamWrapper>

				<ConsultantTitle>{consultantTitle}</ConsultantTitle>
				<TeamWrapper>
					<TeamRow>
						<Column1>
							<a href={link9} target="_blank" rel="noreferrer"><img class="teamImg" src={img9} alt={alt9} /></a>
							<TeamName>{name9}</TeamName>
							<Description>{description9}</Description>
						</Column1>
						<Column2>
							<a href={link12} target="_blank" rel="noreferrer"><img class="teamImg" src={img12} alt={alt12} /></a>
							<TeamName>{name12}</TeamName>
							<Description>{description12}</Description>
						</Column2>
						<Column3>
							<a href={link14} target="_blank" rel="noreferrer"><img class="teamImg" src={img14} alt={alt14} /></a>
							<TeamName>{name14}</TeamName>
							<Description>{description14}</Description>
						</Column3>
					</TeamRow>
				</TeamWrapper>

				<InvestorTitle>{investorTitle}</InvestorTitle>
				<TeamWrapper>
					<TeamRow3>
						<Column1>
							<a href={link15} target="_blank" rel="noreferrer"><img class="teamImg" src={img15} alt={alt15} /></a>
							<TeamName>{name15}</TeamName>
							<Description>{description15}</Description>
						</Column1>
					</TeamRow3>
				</TeamWrapper>

				<CouncilTitle>{councilTitle}</CouncilTitle>
				<TeamWrapper>
					<TeamRow2>
						<Column1>
							<a href={link16} target="_blank" rel="noreferrer"><img class="teamImg" src={img16} alt={alt16} /></a>
							<TeamName>{name16}</TeamName>
							<Description>{description16}</Description>
						</Column1>
						<Column2>
							<a href={link30} target="_blank" rel="noreferrer"><img class="teamImg" src={img30} alt={alt30} /></a>
							<TeamName>{name30}</TeamName>
							<Description>{description30}</Description>
						</Column2>
					</TeamRow2>
				</TeamWrapper>

				<AdvisoryTitle>{advisoryTitle}</AdvisoryTitle>
				<TeamWrapper>
					<TeamRow>
						<Column1>
							<a href={link17} target="_blank" rel="noreferrer"><img class="teamImg" src={img17} alt={alt17} /></a>
							<TeamName>{name17}</TeamName>
							<Description>{description17}</Description>
						</Column1>
						<Column2>
							<a href={link18} target="_blank" rel="noreferrer"><img class="teamImg" src={img18} alt={alt18} /></a>
							<TeamName>{name18}</TeamName>
							<Description>{description18}</Description>
						</Column2>
						<Column3>
							<a href={link19} target="_blank" rel="noreferrer"><img class="teamImg" src={img19} alt={alt19} /></a>
							<TeamName>{name19}</TeamName>
							<Description>{description19}</Description>
						</Column3>
					</TeamRow>
					<TeamRow2>
						<Column1>
							<a href={link20} target="_blank" rel="noreferrer"><img class="teamImg" src={img20} alt={alt20}/></a>
							<TeamName>{name20}</TeamName>
							<Description>{description20}</Description>
						</Column1>
						<Column2>
							<a href={link21} target="_blank" rel="noreferrer"><img class="teamImg" src={img21} alt={alt21} /></a>
							<TeamName>{name21}</TeamName>
							<Description>{description21}</Description>
						</Column2>
					</TeamRow2>
				</TeamWrapper>

				<PartnerTitle>{partnerTitle}</PartnerTitle>
				<TeamWrapper id="partnersRow">
					<TeamRow>
						<Column1>
							<a href={link22} target="_blank" rel="noreferrer"><img class="teamImg2" src={img22} alt={alt22} /></a>
							<TeamName>{name22}</TeamName>
							<Description>{description22}</Description>
						</Column1>
						<Column2>
							<a href={link23} target="_blank" rel="noreferrer"><img class="teamImg2" src={img23} alt={alt23} /></a>
							<TeamName>{name23}</TeamName>
							<Description>{description23}</Description>
						</Column2>
						<Column3>
							<a href={link24} target="_blank" rel="noreferrer"><img class="teamImg2" src={img24} alt={alt24} /></a>
							<TeamName>{name24}</TeamName>
							<Description>{description24}</Description>
						</Column3>
					</TeamRow>
					<TeamRow id="partnersRow">
						<Column1>
							<a href={link25} target="_blank" rel="noreferrer"><img class="teamImg2" src={img25} alt={alt25}/></a>
							<TeamName>{name25}</TeamName>
							<Description>{description25}</Description>
						</Column1>
						<Column2>
							<a href={link26} target="_blank" rel="noreferrer"><img class="teamImg2" src={img26} alt={alt26} /></a>
							<TeamName>{name26}</TeamName>
							<Description>{description26}</Description>
						</Column2>
						<Column3>
							<a href={link27} target="_blank" rel="noreferrer"><img class="teamImg2" src={img27} alt={alt27} /></a>
							<TeamName>{name27}</TeamName>
							<Description>{description27}</Description>
						</Column3>
					</TeamRow>
					<TeamRow2 id="partnersRow2">
						<Column1>
							<a href={link28} target="_blank" rel="noreferrer"><img class="teamImg2" src={img28} alt={alt28}/></a>
							<TeamName>{name28}</TeamName>
							<Description>{description28}</Description>
						</Column1>
						<Column2>
							<a href={link29} target="_blank" rel="noreferrer"><img class="teamImg2" src={img29} alt={alt29} /></a>
							<TeamName>{name29}</TeamName>
							<Description>{description29}</Description>
						</Column2>
					</TeamRow2>
				</TeamWrapper>
			</TeamContainer>
		</>
	)
}

export default TeamSection