import React from 'react'
import './Hero.css'
import { HeroContainer, HeroH1, DataLink } from './HeroElements'

const HeroSection = ({ img, alt, img2, alt2, link }) => {
	return (
		<HeroContainer>
			<HeroH1>Coming Q4 2024</HeroH1>
			<DataLink href={link} target="_blank">ALTIS-7 Datasheet</DataLink>
			<img class="center-fit" src={img2} alt={alt2}/>
		</HeroContainer>
	)
}

export default HeroSection