import styled from 'styled-components'

export const NewsContainer = styled.div`
	color: #fff;
	background: #010606;
	height: auto;
	padding-top: 50px;
	padding-bottom: 50px;
	// text-align: justify;
	text-justify: distribute;
	hyphens: auto;
	// word-spacing: -2px;

	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
`

export const NewsTitle = styled.div`
	display: flex;
	z-index: 1;
	max-width: 275px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 215px;
	}
`

export const NewsTitle2 = styled.div`
	display: flex;
	z-index: 1;
	max-width: 275px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-top: 75px;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 215px;
	}
`

export const NewsWrapper = styled.div`
	// display: grid;
	z-index: 1;
	height: auto;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
	justify-content: left;
`

export const Headline = styled.h2`
	padding-top: 20px;
	margin-bottom: 15px;
	font-size: 20px;
	line-height: 1.1;
	font-weight: 600;
	color: #fff;

	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
`

export const ArticleDate = styled.h4`
	max-width: 440px;
	margin-bottom: 10px;
	font-size: 18px;
	line-height: 20px;
	font-weight: 200;
	color: #fff;

	@media screen and (max-width: 480px) {
		font-size: 16px;
	}
`

export const descDiv = styled.div`
	display: block;
`

export const Description = styled.div`
	// display: inline-flex;
	max-width: 1100px;
	padding-bottom: 25px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	// white-space: nowrap;

	@media screen and (max-width: 480px) {
		font-size: 14px;
	}
`

export const ArticleLink = styled.a`
	display: inline;
	// white-space: nowrap;
	color: #5DA2F2;
	// font-size: 16px;
	// padding-left: 3px;
	// display: inline;

	&:hover {
		color: #fff;
		transition: 0.2s ease-out
	}
`

export const GalleryButton = styled.a`
	border-radius: 50px;
	background: #5DA2F2;
	width: 100%;
	max-width: 220px;
	white-space: nowrap;
	padding: 12px 30px;
	color: #010606;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
	}

	@media screen and (max-width: 768px) {
		// display: inline-flex;
		// margin: 0 auto;
	}
`