export const devObjOne = {
	id: "dev",
	devTitle: "Research & Development",

	heading: "Attochron's Advantages Through the Air",
	heading2: "Attochron Incoherent Summation Advantage",
	description: "Attochron's scientific research in, and demonstrations of, propagation improvements for Attochron-spec USPL optical wireless systems date from the late 1990's and include a seminal period beginning in 2010 at the US Army's Picatinny Arsenal which continues to this day. In 2010, Attochron's USPL (in optical wireless propagation demonstrations) exhibited improvements of ~30dB in all weather in direct comparison to CW/DFB laser sources. In 2011, Attochron demonstrated the first On-off Key (OOK) data-modulation of an USPL in history including bit error rate (BER) tests which were also performed at the US Army's Picatinny Arsenal's wireless testbed. Since then, Attochron has pushed the boundaries of optical wireless systems with various partners and research institutions, culminating in a purpose built, fully instrumented outdoor link range with laboratories in Lexington, Virginia.",
	description2: "Key to Attochron's proprietary system is the very short coherence length of the signals. Lasting approximately 10^-14/seconds these pulses provide a number of advantages. They include extreme spectral bandwidth (~3500 GHz), high peak pulse power (kW+), and extremely precise timing (femtosecond jitter). When propagating through atmosphere, Attochron-spec USPLs leverage the very short coherence length of each pulse with relation to the ever-changing atmosphere. This results in a near-elimination of one of the largest challenges to optical wireless communications: *scintillation*.",
	description3: 'In the black and white GIF we see "what the laser sees" when beaming through the atmosphere: a mirage-like condition that varies depending on weather conditions. The colored GIF shows the comparison of the Attochron-spec USPL and matched power and center wavelength industry-standard CW/DFB being imaged simultaneously at a 1-mile distance in highly scintillating air conditions.',
	description6: 'We witness the long coherence length of the CW/DFB laser being subjected to the rapid changes in the atmosphere which causes coherent interference. This interference can be seen as the "speckle" and the fine, grainy appearance of the CW/DFB beam on the right side of the colored GIF.', 
	description8: 'This effect is of great importance to the Low Probability of Intercept (LPI) and Low Probability of Detection (LPD) defense communities due to the reduced scattering exhibited by the Attochron-spec USPL versus the CW/DFB laser. The Fast Fourier Transform (FFT) images (directly below the colored GIF) exhibit the respective scattering profiles of the beams with light farther away from the central spike (depicted in yellow) representing larger scattering angles.',
	description7: 'This speckle creates noise causing BER to suffer due to the coherent interference. This phenomena also makes it difficult to focus the received light on a small detector or fiber entrance when working with fast data rates and sensitive detectors.',
	description4: 'By contrast, the Attochron-spec USPL with ISA, on the left in the colored GIF, does not experience this coherent interference. Any shifting of the USPL pulses by the atmosphere only occurs outside the pulse coherence length (see propagation model graphic). USPL pulses simply add their power together without the possibility of the electric fields in each pulse interfering with each other and causing noise. This video shows the superior beam quality of the USPL and ISA.',
	description5: 'In the last photo Attochron consultant Dr. Wayne Knox, Professor at University of Rochester sites Attochron FLOW link in New York City, USA.',

	img: require('../../images/IRGIF.gif'),
	img2: require('../../images/SCINTGIF.gif'),
	img3: require('../../images/wayneNY.png'),
	img4: require('../../images/barn.png'),
	img5: require('../../images/propModel.png'),
	img6: require('../../images/imagej.png'),
	alt: "R&D",
	alt2: "R&D",
	alt3: "R&D",
	alt4: "R&D",
	alt5: "R&D",
	alt6: "R&D",

	link2: "https://ln5.sync.com/dl/de84ef6b0/jbzb3s72-n8zmcnc6-c5vdr9ek-kngg4kpi",
	link: require("../../docs/2022 Attochron USPL FSO-OWC Coherence Benefit - White Paper - 102222.pdf"),
	link3: "https://patents.google.com/?assignee=attochron&oq=attochron",
	link4: require("../../docs/Attochron_ALTIS-7_Specifications 032724.pdf"),
}