import React from 'react'
import { NewsContainer, NewsTitle, NewsTitle2, NewsWrapper, Headline, ArticleDate, Description, ArticleLink, GalleryButton } from './NewsElements'

const NewsSection = ({ newsTitle, newsTitle2,
headline1, articleDate1, description1, articleLink1, ariaLabel1,
headline2, articleDate2, description2, articleLink2, ariaLabel2,
headline3, articleDate3, description3, articleLink3, ariaLabel3,
headline4, articleDate4, description4, articleLink4, ariaLabel4,
headline5, articleDate5, description5, articleLink5, ariaLabel5,
headline6, articleDate6, description6, articleLink6, ariaLabel6,
headline7, articleDate7, description7, articleLink7, ariaLabel7,
headline8, articleDate8, description8, articleLink8, ariaLabel8,
headline9, articleDate9, description9, articleLink9, ariaLabel9,
headline10, articleDate10, description10, articleLink10, ariaLabel10,
headline11, articleDate11, description11, articleLink11, ariaLabel11 }) => {
	return (
		<NewsContainer id="news">
			<NewsTitle>{newsTitle}</NewsTitle>
			{/* <GalleryButton href="https://www.instagram.com/attochronllc/" target="_blank">View Our Gallery</GalleryButton> */}
			<NewsWrapper>
				<Headline>{headline11}</Headline>
				{/* <ArticleDate>{articleDate11}</ArticleDate> */}
				<Description>{description11}<ArticleLink href={articleLink11} target="_blank" aria-label={ariaLabel11}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline1}</Headline>
				{/* <ArticleDate>{articleDate1}</ArticleDate> */}
				<Description>{description1}<ArticleLink href={articleLink1} target="_blank" aria-label={ariaLabel1}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline2}</Headline>
				{/* <ArticleDate>{articleDate2}</ArticleDate> */}
				<Description>{description2}<ArticleLink href={articleLink2} target="_blank" aria-label={ariaLabel2}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline3}</Headline>
				{/* <ArticleDate>{articleDate3}</ArticleDate> */}
				<Description>{description3}<ArticleLink href={articleLink3} target="_blank" aria-label={ariaLabel3}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline4}</Headline>
				{/* <ArticleDate>{articleDate4}</ArticleDate> */}
				<Description>{description4}<ArticleLink href={articleLink4} target="_blank" aria-label={ariaLabel4}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline5}</Headline>
				{/* <ArticleDate>{articleDate5}</ArticleDate> */}
				<Description>{description5}<ArticleLink href={articleLink5} target="_blank" aria-label={ariaLabel5}>Read More</ArticleLink></Description>
			</NewsWrapper>
			
			<NewsTitle2>{newsTitle2}</NewsTitle2>
			<NewsWrapper>
				<Headline>{headline6}</Headline>
				{/* <ArticleDate>{articleDate6}</ArticleDate> */}
				<Description>{description6}<ArticleLink href={articleLink6} target="_blank" aria-label={ariaLabel6}>Read More</ArticleLink></Description>
			</NewsWrapper>
			{/*
			<NewsWrapper>
				<Headline>{headline7}</Headline>
				<ArticleDate>{articleDate7}</ArticleDate>
				<Description>{description7}<ArticleLink href={articleLink7} target="_blank" aria-label={ariaLabel7}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline8}</Headline>
				<ArticleDate>{articleDate8}</ArticleDate>
				<Description>{description8}<ArticleLink href={articleLink8} target="_blank" aria-label={ariaLabel8}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline9}</Headline>
				<ArticleDate>{articleDate9}</ArticleDate>
				<Description>{description9}<ArticleLink href={articleLink9} target="_blank" aria-label={ariaLabel9}>Read More</ArticleLink></Description>
			</NewsWrapper>
			<NewsWrapper>
				<Headline>{headline10}</Headline>
				<ArticleDate>{articleDate10}</ArticleDate>
				<Description>{description10}<ArticleLink href={articleLink10} target="_blank" aria-label={ariaLabel10}>Read More</ArticleLink></Description>
			</NewsWrapper>
			*/}
		</NewsContainer>
	)
}

export default NewsSection