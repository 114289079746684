import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import HeroSection from '../components/HeroSection2';
import AboutSection from '../components/AboutSection';
// import TechSection from '../components/TechSection';
import ResearchSection from '../components/ResearchSection';
import NewsSection from '../components/NewsSection';
import TeamSection from '../components/TeamSection';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import { heroObjOne } from '../components/HeroSection2/Data';
import { aboutObjOne } from '../components/AboutSection/Data';
// import { techObjOne } from '../components/TechSection/Data';
import { devObjOne } from '../components/ResearchSection/Data';
import { newsObjOne } from '../components/NewsSection/Data';
import { navLogoObj } from '../components/Navbar/Data';
import { teamObjOne } from '../components/TeamSection/Data';
import { footerLogoObj } from '../components/Footer/Data';

const Home = () => {
	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<Sidebar isOpen={isOpen} toggle={toggle}/>
			<Navbar toggle={toggle} {...navLogoObj}/>
			<HeroSection {...heroObjOne}/>
			<AboutSection {...aboutObjOne}/>
			{/* <TechSection {...techObjOne}/> */}
			<ResearchSection {...devObjOne}/>
			<NewsSection {...newsObjOne}/>
			<TeamSection {...teamObjOne}/>
			<ContactSection/>
			<Footer {...footerLogoObj}/>
		</>
	)
}

export default Home