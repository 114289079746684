import React from 'react';
import './JobStyles.css';
import { JobContainer, DownloadLink, JobH1 } from './JobElements'

const Jobs = ({job1}) => {

    return (
        <JobContainer>
            <JobH1>Administrative Assistant Needed</JobH1>
            <embed title="Administrative Assistant" class="pdf" src={job1}/>
            <DownloadLink href={job1} target="_blank">Download Job Description</DownloadLink>
        </JobContainer>
)}

export default Jobs;