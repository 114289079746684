import styled from 'styled-components'

export const HeroContainer = styled.div`
	background: #000;
	padding: 50px;
	height: 100%;
	text-align: center;
	overflow: hidden;
`

export const HeroH1 = styled.h1`
	color: #fff;
	padding-top: 50px;
	padding-bottom: 25px;
`

export const DataLink = styled.a`
	border-radius: 50px;
	background: #5DA2F2;
	width: 100%;
	max-width: 285px;
	white-space: nowrap;
	padding: 12px 30px;
	color: #010606;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
	}

	@media screen and (max-width: 768px) {
		display: inline-flex;
	}

`