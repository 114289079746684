import React, {useState, useEffect} from 'react'
import { FaBars, FaLinkedin, FaTwitter, FaReddit, FaInstagram } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { animateScroll as scroll } from 'react-scroll'
// import { HashLink } from 'react-router-hash-link'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavLink, NavImg, SocialIcons, SocialIconLink } from './NavbarElements';

const Navbar = ({ toggle, img, alt }) => {
	const [scrollNav, setScrollNav] = useState(false)

	const changeNav = ()=> {
		if(window.scrollY >= 80) {
			setScrollNav(true)
		} else {
			setScrollNav(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNav)
	}, [])

	const toggleHome = () => {
		scroll.scrollToTop();
	}

	return (
		<>
			<IconContext.Provider value={{color: '#fff' }}>
				<Nav scrollNav={scrollNav}>
					<NavbarContainer>
						<NavLogo to='/' onClick={toggleHome}><NavImg src={img} alt={alt}/></NavLogo>
						<MobileIcon onClick={toggle}>
							<FaBars />
						</MobileIcon>
						<NavMenu>
							<NavItem>
								<NavLinks to="about" smooth={true} duration={700} spy={true} exact='true' offset={-80}>About Us</NavLinks>
							</NavItem>
							{/* <NavItem>
								<NavLinks to="technology" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Technologies</NavLinks>
							</NavItem> */}
							<NavItem>
								<NavLinks to="dev" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Research & Development</NavLinks>
							</NavItem>
							{/* <NavItem>
								<NavLink to="data" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Link Data</NavLink>
							</NavItem> */}
							<NavItem>
								<NavLinks to="news" smooth={true} duration={700} spy={true} exact='true' offset={-80}>News & Demos</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks to="team" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Team & Partners</NavLinks>
							</NavItem>
							<NavItem>
								<NavLink to="jobs" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Jobs</NavLink>
							</NavItem>
							<NavItem>
								<NavLinks to="contact" smooth={true} duration={700} spy={true} exact='true' offset={-80}>Contact Us</NavLinks>
							</NavItem>
						</NavMenu>
						{/*)
						<NavBtn>
							<NavBtnLink to="/signin">Sign In</NavBtnLink>
						</NavBtn>
						*/}
						<SocialIcons>
							<SocialIconLink href="//www.linkedin.com/company/attochron-llc/" target="_blank" aria-label="Linkedin">
								<FaLinkedin />
							</SocialIconLink>
							<SocialIconLink href="//twitter.com/attochron1" target="_blank" aria-label="Twitter">
								<FaTwitter />
							</SocialIconLink>
							<SocialIconLink href="//www.reddit.com/user/Attochron/" target="_blank" aria-label="Reddit">
								<FaReddit />
							</SocialIconLink>
							<SocialIconLink href="//www.instagram.com/attochronllc/" target="_blank" aria-label="Instagram">
								<FaInstagram />
							</SocialIconLink>
						</SocialIcons>
					</NavbarContainer>
				</Nav>
			</IconContext.Provider>
		</>
	)
}

export default Navbar;