export const newsObjOne = {
	newsTitle: 'News & Demos',

	headline11: 'Attochron FSOC/OWC in Thick Fog',
	articleDate11: 'November 7, 2022',
	description11: 'ATTOCHRON FSOC/OWC IN THICK FOG: 60dB-90dB attenuation at 1-mile. 1hr time-lapse: Zero BER 2.5Gbps. Attochron links on sale and lease worldwide soon! Worlds Only Carrier Grade FSO/OWC(TM)... ',
	articleLink11: 'https://www.linkedin.com/posts/attochron-llc_attochron-thick-fog-event-1172022-activity-6996255489164509184-2gng?utm_source=share&utm_medium=member_desktop',
	ariaLabel11: 'News11',

	headline1: 'Attochron Significantly Reduces Coherent Interference in FSOC and LiDAR',
	articleDate1: 'September 19, 2022',
	description1: 'In a historical first: Attochron significantly reduces coherent interference in FSOC and LiDAR with the Attochron-spec USPL... ',
	articleLink1: 'https://www.linkedin.com/posts/attochron-llc_laser-telecom-pulse-activity-6977367501194063872-ABIz?utm_source=share&utm_medium=member_desktop',
	ariaLabel1: 'News1',

	headline2: 'Attochron Demonstrates Gameplay over FSOC Link',
	articleDate2: 'May 27, 2022',
	description2: 'Attochron demonstrates first-person Xbox 360 gameplay over their 1-mile FSOC link. During gameplay, Attochron experienced zero dropouts and a zero bit-error rate... ',
	articleLink2: 'https://www.linkedin.com/posts/attochron-llc_attochrons-femtosecond-laser-optical-wireless-activity-6935659310609833984-54zT?utm_source=share&utm_medium=member_desktop',
	ariaLabel2: 'News2',

	headline3: 'Attochron Demonstrates for Isaac Sarver of Senator Mark Warner\'s (D-VA) Office',
	articleDate3: 'May 9, 2022',
	description3: 'Attochron demonstrates for Regional Director Isaac Sarver out of Senator Mark Warner\'s office... ',
	articleLink3: 'https://www.linkedin.com/posts/attochron-llc_backhaul-fso-5g-activity-6926977741166706688-J3pN?utm_source=share&utm_medium=member_desktop',
	ariaLabel3: 'News3',

	headline4: 'Attochron Files Patent For Remotely Powering FSOC Terminals',
	articleDate4: 'March 1, 2022',
	description4: 'Attochron\'s latest patent (US 11,265,088) using the incoming laser communications beam to power the other end of the communications link... ',
	articleLink4: 'https://www.linkedin.com/posts/attochron-llc_femtosecond-5g-ultrashortpulse-activity-6909911154119065600-HEr1?utm_source=share&utm_medium=member_desktop',
	ariaLabel4: 'News4',

	headline5: 'Attochron 1-mile Zoom Demonstration with Jim Olson (Former AT&T Worldnet VP)',
	articleDate5: 'February 14, 2022',
	description5: 'Attochron live zoom demonstration over Attochron 1-mile GIG-E optical wireless (FSO) link for... ',
	articleLink5: 'https://www.linkedin.com/posts/attochron-llc_ultrafast-femtosecond-backhaul-activity-6916401865660223488-QUbC?utm_source=share&utm_medium=member_desktop',
	ariaLabel5: 'News5',

	newsTitle2: 'Industry Trends',
	
	headline6: 'Lumen 20-State ILEC Divesture...[Sharpens Enterprise Focus]',
	articleDate6: 'October 3, 2022',
	description6: 'Attochron congratulates Lumen and stands by ready to help with Attochron FLOW links for enterprises whenever possible. ',
	articleLink6: 'https://www.telecompetitor.com/lumen-20-state-ilec-divestiture-to-brightspeed-closes/',
	ariaLabel6: 'News6',

	headline7: 'News Headline',
	articleDate7: '2022 article date',
	description7: 'Description... ',
	articleLink7: '//www.google.com',
	ariaLabel7: 'News7',

	headline8: 'News Headline',
	articleDate8: '2022 article date',
	description8: 'Description... ',
	articleLink8: '//www.google.com',
	ariaLabel8: 'News8',

	headline9: 'News Headline',
	articleDate9: '2022 article date',
	description9: 'Description... ',
	articleLink9: '//www.google.com',
	ariaLabel9: 'News9',

	headline10: 'News Headline',
	articleDate10: '2022 article date',
	description10: 'Description... ',
	articleLink10: '//www.google.com',
	ariaLabel10: 'News10'
}