import React from 'react'
import './About.css'
// import video from '../../videos/prototypeAnimation3.mp4'
import { AboutContainer, AboutTitle, AboutTitle2, AboutWrapper, AboutRow, Column1, 
	TextWrapper, Heading, Description, Column2, ImgWrap } from './AboutElements'


const AboutSection = ({ id, aboutTitle, aboutTitle2, heading, heading2, description, description2, description3, description4, description5, link, img, img2, alt, alt2 }) => {
	return (
		<>
			<AboutContainer id={id}>
				<AboutTitle2>{aboutTitle2}</AboutTitle2>
				<AboutWrapper>
					<AboutRow>
						<Column1>
							<TextWrapper>
								<Heading>{heading2}</Heading>
								<Description>{description2}</Description>
								<Description>{description3}</Description>
								<Description>{description4}</Description>
								{/*<PatentLink href={link} target="_blank">Attochron Patents</PatentLink> */}
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<img class="aboutImg" src={img2} alt={alt2}/>
							</ImgWrap>
						</Column2>
					</AboutRow>
				</AboutWrapper>
				<AboutTitle>{aboutTitle}</AboutTitle>
				<AboutWrapper>
					<AboutRow>
						<Column1>
							<TextWrapper>
								<Heading>{heading}</Heading>
								<Description>Inspired by <a class="aboutDescLink" href="https://www.jstor.org/stable/24995870" target="_blank" rel="noreferrer">lightning research</a>, Attochron invented a new optical wireless core technology of using <a class="aboutDescLink" href="https://www.rp-photonics.com/ultrashort_pulses.html#:~:text=Ultrashort%20pulses%20(or%20lasers%20generating,processes%20(%E2%86%92%20ultrafast%20optics)" target="_blank" rel="noreferrer">ultrashort pulse lasers (USPL)</a> as the "light wave" signal carrier instead of industry standard continuous wave lasers (CWL). Now, Attochron-spec USPLs are solving some of the longest standing challenges for reliably sending laser signals through the air.</Description> 
								<Description>The most common challenge to this wireless <i>propagation</i> of lasers is known as <a class="aboutDescLink" href='https://www.researchgate.net/figure/Laser-beam-scintillation-turbulent-air-with-cells-smaller-than-the-beam-diameter_fig2_220409352' target="_blank" rel="noreferrer">scintillation</a>, for which Attochron-spec USPLs have effectively solved. The Attochron solution also doubles link budget at the detector with <a class="aboutDescLink" href="//ieeexplore.ieee.org/document/588209" target="_blank" rel="noreferrer">impulsive coding</a> while presenting the opportunity to scale data rates in excess of terabits per second (Tbps) with its extremely broad optical spectrum (equal to 3500 GHz).</Description>
								{/*<PatentLink href={link} target="_blank">Attochron Patents</PatentLink> */}
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<img class="aboutImg" src={img} alt={alt}/>
							</ImgWrap>
						</Column2>
					</AboutRow>
				</AboutWrapper>
			</AboutContainer>
		</>
	)
}

export default AboutSection