import React, {useState} from 'react'
import Navbar from '../components/NavbarData';
import Sidebar from '../components/SidebarData';
import Footer from '../components/Footer';
import Jobs from '../components/JobSection';
import { navLogoObj } from '../components/NavbarData/Data';
import { footerLogoObj } from '../components/Footer/Data';
import { jobObjOne } from '../components/JobSection/Data';

const Data = () => {
	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<Sidebar isOpen={isOpen} toggle={toggle}/>
			<Navbar toggle={toggle} {...navLogoObj}/>
			<Jobs {...jobObjOne}/>
			<Footer {...footerLogoObj}/>
		</>
	)
}

export default Data