import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'

export const DevContainer = styled.div`
	color: #fff;
	background: rgba(64,75,83, 1);
	height: auto;
	padding-top: 50px;
	padding-bottom: 50px;

	@media screen and (max-width: 768px) {
		padding: 50px 0;
	}
`

export const DevTitle = styled.h1`
	display: flex;
	z-index: 1;
	max-width: 420px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 3px solid #5DA2F2;

	@media screen and (max-width: 480px) {
		font-size: 24px;
		text-align: center;
		max-width: 360px;
	}

	@media screen and (max-width: 400px) {
		font-size: 24px;
		text-align: center;
		max-width: 210px;
	}
`

export const DevWrapper = styled.div`
	display: grid;
	z-index: 1;
	width: 100%;
	max-width: 1200px;
	margin-top: 100px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
	justify-content: center;
`

export const DevRow = styled.div`
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: 'col1 col2';

	@media screen and (max-width: 768px) {
		grid-template-areas: 'col1 col1' 'col2 col2';
	}
`

export const Column1 = styled.div`
	margin-bottom: 15px;
	padding: 0 20px;
	grid-area: col1;
	// background-color: #f00;
`

export const TextWrapper = styled.div`
	max-width: 550px;
	padding-top: 0;
	padding-bottom: 60px;

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`

export const Heading = styled.h2`
	margin-bottom: 24px;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 600;
	color: #f7f8fa;

	@media screen and (max-width: 480px) {
		font-size: 24px;
	}
`

export const Heading2 = styled.h2`
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 1.1;
	font-weight: 600;
	color: #f7f8fa;

	@media screen and (max-width: 480px) {
		font-size: 20px;
	}
`

export const Description = styled.p`
	text-align: justify;
	text-justify: auto;
	hyphens: auto;
	word-spacing: -2px;
	max-width: 520px;
	margin-bottom: 35px;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
`

export const Link = styled(LinkS)`
	display: inline;
	color: #5DA2F2;
	cursor: pointer;
`

export const BREAK = styled.div`
	padding: 2px;
`

export const DataLink = styled.a`
	border-radius: 50px;
	background: #5DA2F2;
	width: 100%;
	max-width: 285px;
	white-space: nowrap;
	padding: 12px 30px;
	color: #010606;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
	}

	@media screen and (max-width: 768px) {
		display: inline-flex;
	}

`

export const DataLink2 = styled(LinkS)`
	border-radius: 50px;
	background: #5DA2F2;
	width: 100%;
	max-width: 285px;
	white-space: nowrap;
	padding: 12px 30px;
	color: #010606;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
	}

	@media screen and (max-width: 768px) {
		display: inline-flex;
	}

`

export const Column2 = styled.div`
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
	margin-top: -250px;
	// background-color: #f00;

	@media screen and (max-width: 768px) {
		margin-top: -50px;
	}
`

export const ImgWrap = styled.div`
	max-width: 555px;
	height: 100%;
	border-radius: none;
`